import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  DataTableSkeleton,
  Pagination
} from "carbon-components-react";

import httpRouteService from "../../http-route.service";

import { GlobalContext } from "../../../../App.jsx";

import AppDataTable from "../../../../components/AppDataTable";

const headers = [
  {
    key: "path",
    header: "Path",
  },
  {
    key: "method",
    header: "Method",
  },
  {
    key: "isPublic",
    header: "Public",
  },
  {
    key: "actions",
    header: "Actions",
  }
];

const List = () => {
  const ctx = useContext(GlobalContext);
  const navigate = useNavigate();

  const { user } = ctx;

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const ActionLinks = ({ uid }) => (
    <ul style={{ display: "flex" }}>
      <li>
        <Link to={`/http-routes/${uid}`}>Detail</Link>
      </li>
      <li>
        <span>&nbsp;|&nbsp;</span>
        <Link to={`/http-routes/${uid}/extra`}>Extra</Link>
      </li>
    </ul>
  );

  const getRowItems = rows =>
    rows.map(row => ({
      ...row,
      id: "" + row.id,
      key: row.id,
      actions: <ActionLinks uid={row.uid} />,
    }));

  useEffect(() => {
    if (!user) {
      return navigate("/");
    }

    const fetchData = async () => {
      setLoading(true);

      const data = await httpRouteService.getAll();

      setRows(getRowItems(data));
      setLoading(false);
    }

    fetchData();
  }, [navigate, user]);

  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  return (
    <div className="bx--grid bx--grid--full-width bx--grid--no-gutter http_route_list-page">
      <div className="bx--row http_route_list-page__r1">
        <div className="bx--col-lg-16">
          <div style={{ marginBottom: "1rem" }}>
            <Link to='/http-routes/create'>Create</Link>
          </div>

          {
            loading ?
              <DataTableSkeleton
                columnCount={headers.length + 1}
                rowCount={10}
                headers={headers}
              />
              :
              <>
                <AppDataTable
                  title={"HTTP Routes"}
                  description={"List of HTTP routes"}
                  headers={headers}
                  rows={rows.slice(
                    firstRowIndex,
                    firstRowIndex + currentPageSize
                  )}
                />
                <Pagination
                  totalItems={rows.length}
                  backwardText="Previous page"
                  forwardText="Next page"
                  pageSize={currentPageSize}
                  pageSizes={[5, 10, 15, 25]}
                  itemsPerPageText="Items per page"
                  onChange={({ page, pageSize }) => {
                    if (pageSize !== currentPageSize) {
                      setCurrentPageSize(pageSize);
                    }
                    setFirstRowIndex(pageSize * (page - 1));
                  }}
                />
              </>
          }
        </div>
      </div>
    </div>
  );
};

export default List;