import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Button,
  Select,
  SelectItem,
  TextInput,
  Toggle,
  InlineNotification,
  SelectSkeleton,
  TextInputSkeleton,
  ToggleSkeleton,
  ButtonSkeleton,
  NotificationActionButton
} from "carbon-components-react";

import httpRouteService from "../../http-route.service";

import { GlobalContext } from "../../../../App.jsx";

const Detail = () => {
  const ctx = useContext(GlobalContext);
  const navigate = useNavigate();

  const { user } = ctx;

  const { uid } = useParams();

  const [loading, setLoading] = useState(false);

  const [method, setMethod] = useState("");
  const [invalidMethod, setInvalidMethod] = useState(false);
  const [path, setPath] = useState("");
  const [invalidPath, setInvalidPath] = useState(false);
  const [permissionUid, setPermissionUid] = useState("");
  // const [invalidPermissionUid, setInvalidPermissionUid] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const [deleting, setDeleting] = useState(false);

  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (!user) {
      return navigate("/");
    }

    const fetchData = async () => {
      setLoading(true);

      try {
        const row = await httpRouteService.getOne({ uid });

        setMethod(row.method);
        setPath(row.path);
        setPermissionUid(row.permissionUid);
        setIsPublic(row.isPublic);

      } catch (error) {
        setError(error.message);
      }

      setLoading(false);
    }

    fetchData();
  }, [navigate, uid, user]);



  const [message, setMessage] = useState(undefined);

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();

    if (!method) {
      setInvalidMethod(true);
      return;
    }
    setInvalidMethod(false);

    if (!path) {
      setInvalidPath(true);
      return;
    }
    setInvalidPath(false);

    try {
      const { message } = await httpRouteService.update({
        uid,
        method,
        path,
        permissionUid,
        isPublic,
      });

      setMessage(message);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteClick = async (event) => {
    event.preventDefault();

    try {
      const { message } = await httpRouteService.delete({ uid });

      setMessage(message);

      setDeleting(false);
    } catch (error) {
      setError(error.message);
    }
  }

  return (
    <div className="bx--grid bx--grid--full-width bx--grid--no-gutter http_route_create-page">
      <div className="bx--row http_route_create-page__r1">
        <div className="bx--offset-lg-5 bx--col-lg-6 bx--col-md-8 bx--col-sm-4">
          <span>Handle a HTTP route</span>
          <Form onSubmit={handleUpdateSubmit}>
            <div style={{ marginBottom: "1rem" }}>
              {
                loading ?
                  (<SelectSkeleton />)
                  :
                  <Select
                    id="method-select"
                    invalid={invalidMethod}
                    invalidText="A valid value is required"
                    labelText="Method"
                    onChange={(event) => setMethod(event.target.value)}
                    value={method}
                  >
                    <SelectItem
                      text="Select..."
                      value=""
                    />
                    <SelectItem
                      text="GET"
                      value="get"
                    />
                    <SelectItem
                      text="POST"
                      value="post"
                    />
                    <SelectItem
                      text="PUT"
                      value="put"
                    />
                    <SelectItem
                      text="PATCH"
                      value="patch"
                    />
                    <SelectItem
                      text="DELETE"
                      value="delete"
                    />
                  </Select>
              }
            </div>

            <div style={{ marginBottom: "1rem" }}>
              {
                loading ?
                  (<TextInputSkeleton />)
                  :
                  <TextInput
                    id="path"
                    labelText="Path"
                    invalid={invalidPath}
                    invalidText="A valid value is required"
                    onChange={(event) => setPath(event.target.value)}
                    value={path}
                  />
              }
            </div>

            <div style={{ marginBottom: "1rem" }}>
              {
                loading ?
                  (<TextInputSkeleton />)
                  :
                  <TextInput
                    id="permissionUid"
                    labelText="Permission UID"
                    // invalid={invalidPermissionUid}
                    invalidText="A valid value is required"
                    onChange={(event) => setPermissionUid(event.target.value)}
                    value={permissionUid}
                  />
              }
            </div>

            <div style={{ marginBottom: "1rem" }}>
              {
                loading ?
                  (<ToggleSkeleton />)
                  :
                  <Toggle
                    id="isPublic-toggle"
                    labelText="Is public?"
                    onChange={(event) => setIsPublic(event.target.checked)}
                    defaultToggled={isPublic}
                  />
              }
            </div>

            <div>
              {
                error &&
                                <InlineNotification
                                  kind="error"
                                  iconDescription="close button"
                                  subtitle={<span>{error}</span>}
                                  title="Uups!"
                                  onClose={() => setError(undefined)}
                                />
              }
            </div>

            <div>
              {
                message &&
                                <InlineNotification
                                  kind="success"
                                  iconDescription="close button"
                                  subtitle={<span>{message}</span>}
                                  title="Success!"
                                  onClose={() => setMessage(undefined)}
                                />
              }
            </div>

            <div style={{ marginBottom: "1rem" }}>
              {
                loading ?
                  (<ButtonSkeleton className="btn-block" size="field" />)
                  :
                  <Button className="btn-block" type="submit" size="field">Update</Button>
              }
            </div>

            <div>
              {
                deleting &&

                                <InlineNotification
                                  kind="warning"
                                  iconDescription="close button"
                                  subtitle={<span>are you sure?</span>}
                                  title="Alert!"
                                  onClose={() => setDeleting(false)}
                                  actions={
                                    <NotificationActionButton onClick={handleDeleteClick}>
                                            Cotinue
                                    </NotificationActionButton>
                                  }
                                />
              }
            </div>

            <div style={{ marginBottom: "1rem" }}>
              {
                loading ?
                  (<ButtonSkeleton className="btn-block" size="field" />)
                  :
                  <Button kind="danger" className="btn-block" size="field" onClick={() => setDeleting(true)}>Delete</Button>
              }
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Detail;
