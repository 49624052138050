import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Content } from "carbon-components-react";

import "./app.scss";

import firebaseApp from "./firebase";

import Header from "./components/AppHeader";

import Login from "./modules/auth/views/Login";
import OTP from "./modules/auth/views/OTP";
import Home from "./modules/main/views/Home";
import HttpRouteList from "./modules/http-route/views/List";
import HtttpRouteCreate from "./modules/http-route/views/Create";
import HtttpRouteDetail from "./modules/http-route/views/Detail";

export const GlobalContext = React.createContext();

const App = () => {
  const [user, setUser] = useState({});

  const auth = getAuth(firebaseApp);

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  return (
    <div className="App">
      <GlobalContext.Provider value={{ user }}>
        <Header />
        <Content>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/otp" element={<OTP />} />
            <Route path="/home" element={<Home user={user} />} />
            <Route path="/http-routes" element={<HttpRouteList />} />
            <Route path="/http-routes/create" element={<HtttpRouteCreate />} />
            <Route path="/http-routes/:uid" element={<HtttpRouteDetail />} />
          </Routes>
        </Content>
      </GlobalContext.Provider>
    </div>
  );
}

export default App;