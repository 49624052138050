import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Select,
  SelectItem,
  TextInput,
  Toggle,
  InlineNotification,
} from "carbon-components-react";

import httpRouteService from "../../http-route.service";

import { GlobalContext } from "../../../../App.jsx";

const Create = () => {
  const ctx = useContext(GlobalContext);
  const navigate = useNavigate();

  const { user } = ctx;

  useEffect(() => {
    if (!user) {
      return navigate("/");
    }
  }, [navigate, user]);

  const [method, setMethod] = useState("");
  const [invalidMethod, setInvalidMethod] = useState(false);
  const [path, setPath] = useState("");
  const [invalidPath, setInvalidPath] = useState(false);
  const [permissionUid, setPermissionUid] = useState("");
  // const [invalidPermissionUid, setInvalidPermissionUid] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const handleCreateSubmit = async (event) => {
    event.preventDefault();

    if (!method) {
      setInvalidMethod(true);
      return;
    }
    setInvalidMethod(false);

    if (!path) {
      setInvalidPath(true);
      return;
    }
    setInvalidPath(false);

    try {
      const { message } = await httpRouteService.create({
        method,
        path,
        permissionUid,
        isPublic,
      });

      setMessage(message);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="bx--grid bx--grid--full-width bx--grid--no-gutter http_route_create-page">
      <div className="bx--row http_route_create-page__r1">
        <div className="bx--offset-lg-5 bx--col-lg-6 bx--col-md-8 bx--col-sm-4">
          <span>Create a HTTP route</span>
          <Form onSubmit={handleCreateSubmit}>
            <div style={{ marginBottom: "1rem" }}>
              <Select
                defaultValue=""
                id="method-select"
                invalid={invalidMethod}
                invalidText="A valid value is required"
                labelText="Method"
                onChange={(event) => setMethod(event.target.value)}
              >
                <SelectItem
                  text="Select..."
                  value=""
                />
                <SelectItem
                  text="GET"
                  value="get"
                />
                <SelectItem
                  text="POST"
                  value="post"
                />
                <SelectItem
                  text="PUT"
                  value="put"
                />
                <SelectItem
                  text="PATCH"
                  value="patch"
                />
                <SelectItem
                  text="DELETE"
                  value="delete"
                />
              </Select>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <TextInput
                id="path"
                labelText="Path"
                invalid={invalidPath}
                invalidText="A valid value is required"
                onChange={(event) => setPath(event.target.value)}
              />
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <TextInput
                id="permissionUid"
                labelText="Permission UID"
                // invalid={invalidPermissionUid}
                invalidText="A valid value is required"
                onChange={(event) => setPermissionUid(event.target.value)}
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <Toggle
                id="isPublic-toggle"
                labelText="Is public?"
                defaultValue={false}
                onChange={(event) => setIsPublic(event.target.checked)}
              />
            </div>

            {
              error &&
                            <InlineNotification
                              kind="error"
                              iconDescription="close button"
                              subtitle={<span>{error}</span>}
                              title="Uups!"
                              onClose={() => setError(undefined)}
                            />
            }

            {
              message &&
                            <InlineNotification
                              kind="success"
                              iconDescription="close button"
                              subtitle={<span>{message}</span>}
                              title="Success!"
                              onClose={() => setMessage(undefined)}
                            />
            }

            <div style={{ marginBottom: "1rem" }}>
              <Button className="btn-block" type="submit" size="field">Send</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Create;
